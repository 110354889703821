import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { withFirebase } from '../components/firebase'
import FocusHeader from '../components/global/focus-header'
import { Wrap } from '../components/page/components'
import FocusBody from '../components/page/focus-body'
import SEO from '../components/SEO'
import { AuthUserContext } from '../components/session'

const Welcome = ({ firebase }) => {
  let body
  if (firebase) {
    body = (
      <AuthUserContext.Consumer>
        {({ authUser }) =>
          authUser ? <Auth firebase={firebase} /> : <NonAuth />
        }
      </AuthUserContext.Consumer>
    )
  } else {
    body = <div>Please hold...</div>
  }

  return (
    <Wrap>
      <SEO title="Welcome" />
      <FocusHeader title="Welcome" hideBackUrl={true} />
      <FocusBody>{body}</FocusBody>
    </Wrap>
  )
}

export default withFirebase(Welcome)

const Auth = ({ firebase }) => {
  useEffect(() => {
    firebase.doSignOut()
  }, [])

  return <p>Please hold...</p>
}

const NonAuth = () => {
  useEffect(() => {
    navigate('/')
  }, [])

  return <p>Redirecting...</p>
}
